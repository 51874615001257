body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-image: url("./media/img/m-w.jpg");
  /* background-image:url('../images/background.jpg'); */
  background-attachment:fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #3c4457;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px #221f1f;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.input-fld{
    display: flex;
    flex-direction: column;
    gap:6px;
    input{
        font-size: 22px;
        border: none;
        border-radius: 2px;
        padding: 8px 12px;
    }
    input.not-valid{
        background-color: rgb(231, 204, 204);
    }
    label{
        font-size: 24px;
        padding-left: 12px;
    }
    .fld-validation{
        font-size: 18px;
        color: rgb(194, 78, 78);
    }
}
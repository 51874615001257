header{
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}
.nav-item{
    padding: 8px 12px;
}

.nav-brand{
    cursor: pointer;
    font-size: 28px;
}

.nav-logo{
    display: flex;
    cursor: pointer;
    font-size: large;
    background-color: rgba(13, 60, 102, 0.096);
    border-radius: 20%;
    margin: auto;
    height:75px;
}

.nav-left{
    display: flex;
}
.nav-right{
    display: flex;
}
.login-form{
    display: flex;
    flex-direction: column;
    position: relative;

    .form{
        border-radius: 6px;
        padding: 18px 18px;
        background-color: rgba(55, 133, 202, 0.116);
        position: absolute;
        left: -200px;
        bottom: 50px;
    }
    input{
        border-radius: 2px;
        padding: 6px 14px;
        width:200px;
        line-height: normal;
        font-size: 18px;
        border:none;
    }
    input.not-valid{
        background-color: rgb(187, 164, 187);
    }
    .tool{
        padding-top: 16px;
        display: flex;
        gap:6px;
    }
    button{
        cursor: pointer;
        border-radius: 2px;
        padding: 6px 12px;
        width:60px;
        border:none;
    }
    button:hover{
        background-color: rgba(224, 225, 231, 0.822);
    }
    button:active{
        background-color: rgba(174, 216, 210, 0.822);
    }
    .login-result{
        height: 12px;
        color: rgb(228, 171, 184);
    }
    .login-fld-not-valid{
        position: absolute;
        right: 20px;
        font-size: 12px;
        color: red;
    }
}
.logout{
    cursor: pointer;
}
.login-link{
    cursor: pointer;
}
.article-lib-edit {
    /* width:calc(100% - 10px); */
    flex-shrink: 0;
    color: rgb(212, 223, 233);
    font-size: 28px;
    font-weight: 500;
    display: flex;
    border-radius: 6px;
    cursor: pointer;
    padding: 12px 12px;
    transition: all 100ms ease-in-out;

}

.article-card-tool{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 4px 12px;
}


.login-callback{
    display: flex;
    position: static;
    height: 100%;
    color:aliceblue;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.685);
    height: 100vh;
    .login-callback-content{
        font-size: 34px;

        &.error{
            color: rgb(215, 214, 236);
        }

    }
}
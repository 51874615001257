.article-lib-content {
    width:70%;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    border-radius: 5px 5px 5px 5px;

    border-radius: 6px;
    padding: 12px 12px;
    background-color: rgba(78, 64, 99, 0.13);
    transition: all 100ms ease-in-out;
    .title{
        border-radius: 5px 5px 0px 0px;
        font-size: 30px;
        font-weight: 600;
        display: flex;
        background-color: rgba(21, 43, 61, 0.829);
        height: 60px;
        padding-left: 12px;
        padding-right: 12px;
        align-items: center;
    };
    .content{
        display: flex;
        flex-direction: column;
        // background-color: rgba(12, 11, 43, 0.801);
        background-color: rgba(21, 43, 61, 0.842);
        height: calc(100% - 120px);
        overflow-y: auto;
        overflow-wrap: break-word;
        .page-title{
            text-align: center;
            font-size: 22px;
            padding-bottom: 8px;
            padding-top: 8px;
        }
        .content-paragrarh-title{
            padding-left: 18px;
            font-size: 20px;
            padding-bottom: 6px;
        }
        .content-paragrarh-text{
            padding-left: 10px;
            text-indent: 30px;
        }
    };
    .footer{
        display: flex;
        background-color: rgba(21, 43, 61, 0.842);
        height: 60px;
        padding-left: 12px;
        padding-right: 12px;
        align-items: center;
        border-radius: 0px 0px 5px 5px;
    };

}

// .article-lib-content.selected {
//     display: flex;
//     box-shadow: 1;

// }
// .article-lib-content:hover {
//     scale: 1.01;
//     background-color: rgba(240, 248, 255, 0.075);

// }

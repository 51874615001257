.card {
    flex-direction: column;
    display: flex;
    border-radius: 6px;
    background-color: rgba(57, 47, 85, 0.199);

    .card-title {
        display: flex;
        justify-content: space-between;
        padding: 8px 12px;
        font-size: 24px;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        padding: 8px 12px;
        font-size: 24px;
    }

    .card-footer {
        display: flex;
        justify-content: space-between;
        padding: 8px 12px;
        font-size: 24px;
    }
}

.ra-btn{
    background-color: rgba(3, 128, 230, 0.61);
    color: aliceblue;
    display: flex;
    border: none;
    border-radius: 2px;
    padding: 8px 18px;
    font-size: 20px;
    min-width: 110px;
    transition: all 30ms ease-in-out;
    justify-content: center;
    &:hover{
        background-color: rgba(39, 75, 105, 0.603);
    }
    &:active{
        background-color: rgba(7, 58, 102, 0.548);
    }
}
footer{
    flex-shrink: 0;
    height: 100px;
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    align-items: center;
}
.footer-left{
    display: flex;
}
.footer-right{
    display: flex;
}
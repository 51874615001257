.home {
    display: flex;
    padding: 20px;
    height: calc(100% - 100px);
}

.lib-articles{
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    overflow-y: auto;
    gap : 12px;
}
.article-lib {
    /* width:calc(100% - 10px); */
    flex-shrink: 0;
    color: rgb(212, 223, 233);
    font-size: 28px;
    font-weight: 500;
    display: flex;
    height: 100px;
    border-radius: 6px;
    cursor: pointer;
    padding: 12px 12px;
    background-color: rgba(8, 55, 94, 0.048);
    transition: all 100ms ease-in-out;

}

.article-lib-inner{
    border-radius: 6px;
    padding: 8px 12px;
    position: relative;
    width: 100%;
    background-color: rgba(27, 47, 63, 0.719);
}

.article-lib.selected {
    display: flex;
    background-color: rgba(56, 82, 105, 0.164);
}
.article-lib.tool {
    height: 60px;
    display: flex;
}
.article-lib:hover {
    background-color: rgba(56, 82, 105, 0.164);

}
.article-icon{
    height: 40px;
    width: 40px;
    border-radius: 30%;
}
.article-lib-top{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.edit-article-btn{
    width: min-content;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
}
.edit-article-btn:hover{
    color: rgb(155, 155, 221);
}

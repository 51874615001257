.App {
  color: aliceblue;
  display: grid;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.app-content{
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  width:1000px;
  justify-self: center;
}
main {
  height: calc(100vh - 200px);
}



.input-checkbox {
    display: flex;
    flex-direction: row;

    label {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 20px;

        svg {
            .rect {
                transition: all 100ms ease-in-out;
            }

            .path {
                transition: all 100ms ease-in-out;
            }
        }

        span {
            padding-left: 6px;
        }
    }

    input[type=checkbox] {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
    }

    input[type=checkbox]:not(:checked)+label {
        .rect {
            fill-opacity: 1;
            fill:none;
        }

        .path {
            fill-opacity: 1;
            fill: rgba(240, 248, 255, 0);
            stroke: rgba(240, 248, 255, 0);
        }
    }

    input[type=checkbox]:checked+label {
        .rect {
            fill: #456EBF;
            stroke: #456EBF;
        }

        .path {
            fill-opacity: 0;
        }
    }
}